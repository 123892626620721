import { type VariantProps, cva } from 'class-variance-authority'
import { type HTMLAttributes } from 'react'

import { cn } from '#lib/utils.js'

const badgeVariants = cva(
  'focus:ring-ring inline-flex w-fit items-center rounded-full font-medium transition-colors focus:outline-hidden focus:ring-2 focus:ring-offset-2 select-none shrink-0 hover:bg-opacity-65',
  {
    variants: {
      variant: {
        default: 'bg-primary text-primary-foreground',
        secondary:
          'bg-muted text-secondary-foreground dark:bg-muted/30 dark:text-secondary-foreground',
        destructive:
          'bg-rose-100 text-rose-800 dark:bg-rose-500/30 dark:text-rose-200/80',
        warning:
          'bg-warning-100 text-warning-800 dark:bg-warning-500/30 dark:text-warning-200/80',
        outline: 'text-foreground border',
        success:
          'bg-success-100 text-success-800 dark:bg-success-500/30 dark:text-success-200/80',
        indigo:
          'bg-indigo-100 text-indigo-800 dark:bg-indigo-500/30 dark:text-indigo-200/80',
        purple:
          'bg-purple-100 text-purple-500 dark:bg-purple-500/30 dark:text-purple-200/80',
        slate:
          'bg-slate-100 text-slate-600 dark:bg-slate-700/40 dark:text-slate-200/80',
        stone:
          'bg-stone-200 text-stone-600 dark:bg-stone-500/30 dark:text-stone-200/80',
        teal: 'bg-teal-100 text-teal-600 dark:bg-teal-500/30 dark:text-teal-200/80',
        brand: 'bg-brand-background text-brand',
        pink: 'bg-pink-100 text-pink-800 dark:bg-pink-500/30 dark:text-pink-200/80',
        sky: 'bg-sky-100 text-sky-600 dark:bg-sky-500/30 dark:text-sky-200/80',
        cyan: 'bg-cyan-100 text-cyan-600 dark:bg-cyan-500/30 dark:text-cyan-200/80',
        gray: 'bg-gray-100 text-gray-700 dark:bg-gray-500/30 dark:text-gray-200/80',
      },
      size: {
        xs: 'h-4.5 text-xxs px-2',
        sm: 'h-5 text-xs px-2',
        default: 'h-5.5 text-sm px-2.5',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
)

export interface BadgeProps
  extends HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

const Badge = ({ className, variant, size, ...props }: BadgeProps) => {
  return (
    <div
      className={cn(badgeVariants({ variant, size }), className)}
      {...props}
    />
  )
}

export { Badge, badgeVariants }
